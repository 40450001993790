import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Signing New Players (via Scout Combinations)"
        description="Build a competitive team in PES 2021 myClub by combining scouts effectively. This is the best way to sign the player you want."
      />
      <div className="container">
        <h1>Signing New Players (via Scout Combinations)</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Signing New Players (via Scout Combinations)</li>
        </ul>
        <p>
          If you want to sign a specific player, you will need a combination of
          scouts. You can get free ones, and you can also go to auctions to
          acquire the ones you’re missing.
        </p>
        <p>
          All players will be level 1 - before PES 2020, this meant that you had
          to train them up to level 30 for them to achieve their default state,
          but now you get the player with his true potential immediately.
        </p>
        <p>
          While you can get duplicate players via Agents, this is not the case
          with Scouts - which means that if you already have a player in your
          squad, you can't scout him again.
        </p>
        <h3>Free Scouts</h3>
        <p>
          After every game you play (or SIM), you will get a free 1, 2, 3 or
          4-star Scout. The number of stars is important, because for a Gold
          Player you will need to combine 4-star Scouts (or mix a 5-star with a
          4-star and a 3-star scout so that it averages 4 stars). For a Silver
          Ball, you can use 3-star Scouts. You can have up to 150 Scouts at any
          given time.
        </p>
        <p>
          If you just try your luck with a single scout, you will have a very
          slim chance of getting a gold player, let alone the one you are
          looking for. You need to combine scouts to increase the likelihood of
          getting a certain player.
        </p>
        <p>
          You can combine up to 3 scouts. Every time you add a new scout, you
          are basically narrowing down the list of players you can get.
        </p>
        <p>
          As an example, let’s say you want to sign Nicolás Tagliafico (Gold
          Ball, 84 rated) to your team. You can combine 3 scouts ("Dutch
          League"/"LB"/"N/S American") and you will have a 50% chance of getting
          him.
        </p>
        <p>
          Want 100%? You will either need to have the other possible player in
          your club already, or you will need to swap "Dutch League" for "Ajax"
          to narrow it down to 100%.
        </p>
        <p>
          The thing is, for a specific club, you need to go to the Auctions
          House (the topic below). You can access{" "}
          <a
            href="http://pesdb.net/pes2021/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PES DB
          </a>{" "}
          to see the combinations you need for the players you want.
        </p>
        <p>
          There are some players you can get by just combining 3 free scouts -
          Perisic, for instance, is the only player possible if you combine the
          following 4* scouts: "Other (Europe)"/"LMF"/"Ball Control".
        </p>
        <p>
          <a
            href="http://pesdb.net/pes2021/?scout_percent=100"
            target="_blank"
            rel="noopener noreferrer"
          >
            According to PES DB
          </a>
          , these are all the Gold Ball Players you can get with free scouts
          (100% chance) in PES 2021 (subject to change throughout the year with
          the Data Packs):
        </p>
        <ul className="grid-list">
          <li>A. Candreva</li>
          <li>Á. Correa</li>
          <li>A. Florenzi</li>
          <li>A. Harit</li>
          <li>A. Morelos</li>
          <li>A. Onana</li>
          <li>B. Pavard</li>
          <li>C. Bakambu</li>
          <li>David Luiz</li>
          <li>Douglas Costa</li>
          <li>E. Zahavi</li>
          <li>Emerson</li>
          <li>F. Muslera</li>
          <li>Filipe Luís</li>
          <li>H. Çalhanoğlu</li>
          <li>Hulk</li>
          <li>I. Gueye</li>
          <li>Illarramendi</li>
          <li>J. Grealish</li>
          <li>João Félix</li>
          <li>João Pedro</li>
          <li>Juan Bernat</li>
          <li>L. Alario</li>
          <li>L. Hrádecký</li>
          <li>L. Klostermann</li>
          <li>L. Martínez</li>
          <li>L. Paredes</li>
          <li>M. Acuña</li>
          <li>M. Benatia</li>
          <li>M. Ginter</li>
          <li>M. Hamšík</li>
          <li>M. Marega</li>
          <li>M. Rashford</li>
          <li>M. Ryan</li>
          <li>Malcom</li>
          <li>Marcelo</li>
          <li>Nacho Monreal</li>
          <li>Oscar</li>
          <li>Paulinho</li>
          <li>Pépé</li>
          <li>Pizzi</li>
          <li>R. Cabella</li>
          <li>R. Guerreiro</li>
          <li>Raúl García</li>
          <li>S. Azmoun</li>
          <li>S. El Shaarawy</li>
          <li>S. Giovinco</li>
          <li>S. Lulić</li>
          <li>Sergi Roberto</li>
          <li>V. Claesson</li>
          <li>Vitolo</li>
          <li>Y. Rakitskiy</li>
        </ul>
        <p>
          For PES 2021, you can use this{" "}
          <a
            href="https://docs.google.com/spreadsheets/d/1lSj0B0grwqohhia4XaB97xfknZ6zKEzuh1r54427FEs/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            helpful spreadsheet
          </a>{" "}
          to find out all the players you can sign for free (Bronze, Silver or
          Gold). Make sure you click on "File > Make a Copy" first.
        </p>
        <p>
          The spreadsheet gives you the players you can scout after you
          introduce your scout inventory.
        </p>
        <p>
          You will see that you have a number between 2 and 4 next to each
          possible scout (that's the number of the stars of the scout). As you
          add more and more scouts to the spreadsheet, unlocked players will
          become highlighted in green.
        </p>
        <p>
          As an example, for M. Rashford, if you add "1" next to "English League
          4*", "LWF 4*" and "Offensive Awareness 4*", he will become available.
        </p>
        <h3>Auctions House for specific Scouts</h3>
        <p>
          You don't get Nationality, Club, Skills or Style scouts from games, so
          you will have to come here at some point.
        </p>
        <p>
          When you know which scout(s) you’re missing, you need to place a bid
          for them. I usually just add those scouts to my wishlist and bid
          whenever I notice those scouts are available.
        </p>
        <p>
          You can place your final bid right away, meaning, you don’t have to
          sit and wait until the auction ends. You won’t know your competitors’
          bids; the only stat you can rely on is the latest 5 bids for this
          exact scout, so it’s pretty much a gamble.
        </p>
        <p>
          Keep in mind that you can’t use Coins for this mode - GP is the only
          allowed currency.
        </p>
        <p>
          You’re buying these scouts directly from the game, not another user -
          you can actually sell scouts you own and won’t use, but the GP reward
          is very low, so I wouldn’t recommend it. You’re better off just
          getting a white ball and using it for SIMs, for example.
        </p>
        <p>
          You may have noticed I have never mentioned 5-star scouts in the
          previous topic. That’s because 5-star scouts aren’t a free reward - if
          you want a Black Ball player, you will need to buy all 3 scouts in the
          Auctions House!
        </p>
        <p>
          I would recommend you have a look at what you can get with 50%/33%/25%
          chance instead of spending so much GP to assure you get a 100% chance
          - this is known as clearing the scout pool.
        </p>
        <p>
          Let's say you want a Gold Ball player, and you have 25% chance; the
          other 3 options are all White Balls. You can get those players first
          with cheap/free 1-star Scouts, and then the only player left will be
          the one you are looking for - a 25% chance becomes a 100% chance.
        </p>
      </div>
    </Layout>
  )
}

export default contact
